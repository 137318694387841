import { api_instance } from './Base_Api'

export const get_all_quiz_names = async () => {
    try {
        const { data } = await api_instance.get(
            '/admin/questionnair/all-questionnair-names',
            {}
        )
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_detailed_quiz_module = async (
    questionnairType = 'ATTACHMENT_RELATIONAL'
) => {
    try {
        const { data } = await api_instance.get(
            '/admin/questionnair/questionnair-detail',
            {
                params: {
                    questionnairType,
                },
            }
        )
        return data
    } catch (e) {
        console.log(e.message)
    }
}
