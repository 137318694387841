import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../styles/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Controller } from 'react-hook-form'
import {
    cmHeightArrForUser,
    feetHeightArrForUser,
    heightTypeArr,
} from '../../../Utils'
import { useEffect, useState } from 'react'

const AppearanceAndLifestyle = ({
    register,
    smokingDropdownOptions,
    drinkingDropdownOptions,
    control,
    form,
}) => {
    const heightTypeValue = form.watch('height.type')
    const style = styles()

    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-appearance-style-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Appearance & Lifestyle
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="smoking" sx={{ pt: 4 }}>
                                        Smoking:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('smoking')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="smoking"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Smoking
                                                        </MenuItem>
                                                        {smokingDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="drinking"
                                        sx={{ pt: 4 }}
                                    >
                                        Drinking:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('drinking')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="drinking"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Drinking
                                                        </MenuItem>
                                                        {drinkingDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={2}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="height.type"
                                        sx={{ pt: 4 }}
                                    >
                                        Height Type:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('height.type')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="height.type"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) => {
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }}
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Height Type
                                                        </MenuItem>
                                                        {heightTypeArr?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={2}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    {heightTypeValue != null &&
                                        heightTypeValue != undefined && (
                                            <>
                                                {heightTypeValue === 'CM' ? (
                                                    <>
                                                        <FormLabel
                                                            htmlFor="height.height_cm"
                                                            sx={{ pt: 4 }}
                                                        >
                                                            Height:
                                                        </FormLabel>
                                                        <FormControlLabel
                                                            style={{
                                                                paddingTop:
                                                                    '16px',
                                                                paddingLeft:
                                                                    '12px',
                                                            }}
                                                            control={
                                                                <Controller
                                                                    {...register(
                                                                        'height.height_cm'
                                                                    )}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <>
                                                                            <Select
                                                                                labelId="height.height_cm"
                                                                                id="height.height_cm"
                                                                                fullWidth
                                                                                margin="normal"
                                                                                size="small"
                                                                                value={
                                                                                    field.value
                                                                                        ? field.value
                                                                                        : ''
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    field.onChange(
                                                                                        event
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <MenuItem
                                                                                    value={
                                                                                        '0'
                                                                                    }
                                                                                >
                                                                                    Select
                                                                                    Height
                                                                                    Type
                                                                                </MenuItem>
                                                                                {cmHeightArrForUser?.map(
                                                                                    (
                                                                                        option
                                                                                    ) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={
                                                                                                    option
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    option
                                                                                                }
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }
                                                                                )}
                                                                            </Select>
                                                                        </>
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <FormLabel
                                                            htmlFor="height.height_feet"
                                                            sx={{ pt: 4 }}
                                                        >
                                                            Height:
                                                        </FormLabel>
                                                        <FormControlLabel
                                                            style={{
                                                                paddingTop:
                                                                    '16px',
                                                                paddingLeft:
                                                                    '12px',
                                                            }}
                                                            control={
                                                                <Controller
                                                                    {...register(
                                                                        'height.height_feet'
                                                                    )}
                                                                    control={
                                                                        control
                                                                    }
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <Select
                                                                            labelId="height.height_feet"
                                                                            id="height.height_feet"
                                                                            fullWidth
                                                                            margin="normal"
                                                                            size="small"
                                                                            value={
                                                                                field.value
                                                                                    ? field.value
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                field.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }}
                                                                        >
                                                                            <MenuItem
                                                                                value={
                                                                                    '0'
                                                                                }
                                                                            >
                                                                                Select
                                                                                Height
                                                                                Type
                                                                            </MenuItem>
                                                                            {feetHeightArrForUser?.map(
                                                                                (
                                                                                    option
                                                                                ) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            value={
                                                                                                option
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                option
                                                                                            }
                                                                                        </MenuItem>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </Select>
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                </Stack>
                                <Stack direction="column">
                                    {heightTypeValue == null && (
                                        <>
                                            <FormLabel
                                                htmlFor="height.height_feet"
                                                sx={{ pt: 4 }}
                                            >
                                                Height:
                                            </FormLabel>
                                            <FormControlLabel
                                                style={{
                                                    paddingTop: '16px',
                                                    paddingLeft: '12px',
                                                }}
                                                control={
                                                    <Controller
                                                        {...register(
                                                            'height.height_feet'
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                labelId="height.height_feet"
                                                                id="height.height_feet"
                                                                fullWidth
                                                                margin="normal"
                                                                size="small"
                                                                value={
                                                                    field.value
                                                                        ? field.value
                                                                        : ''
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    field.onChange(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    value={'0'}
                                                                >
                                                                    Select
                                                                    Height Type
                                                                </MenuItem>
                                                                {feetHeightArrForUser?.map(
                                                                    (
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    option
                                                                                }
                                                                            >
                                                                                {
                                                                                    option
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                )}
                                                            </Select>
                                                        )}
                                                    />
                                                }
                                            />
                                        </>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="exercise"
                                        sx={{ pt: 4 }}
                                    >
                                        Exercise:
                                    </FormLabel>
                                    <TextField
                                        {...register('exercise')}
                                        placeholder="Exercise"
                                        id="exercise"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="diet" sx={{ pt: 4 }}>
                                        Diet:
                                    </FormLabel>
                                    <TextField
                                        {...register('diet')}
                                        placeholder="Diet"
                                        id="diet"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="pet" sx={{ pt: 4 }}>
                                        Pet:
                                    </FormLabel>
                                    <TextField
                                        {...register('pet')}
                                        placeholder="Pet"
                                        id="pet"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="likes" sx={{ pt: 4 }}>
                                        Likes:
                                    </FormLabel>
                                    <TextField
                                        {...register('likes')}
                                        placeholder="Likes"
                                        id="likes"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="dislikes"
                                        sx={{ pt: 4 }}
                                    >
                                        Dislikes:
                                    </FormLabel>
                                    <TextField
                                        {...register('dislikes')}
                                        placeholder="Dislikes"
                                        id="dislikes"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="instagram"
                                        sx={{ pt: 4 }}
                                    >
                                        Instagram:
                                    </FormLabel>
                                    <TextField
                                        {...register('instagram')}
                                        placeholder="Instagram"
                                        id="instagram"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="LinkedIn"
                                        sx={{ pt: 4 }}
                                    >
                                        LinkedIn:
                                    </FormLabel>
                                    <TextField
                                        {...register('linkedin')}
                                        placeholder="LinkedIn"
                                        id="linkedin"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default AppearanceAndLifestyle
