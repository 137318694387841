import {
    Grid,
    IconButton,
    Typography,
    Zoom,
    LinearProgress,
    Divider,
    Input,
} from '@material-ui/core'
import { DragIndicator, Label } from '@material-ui/icons'
import moment from 'moment'

import React, { useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import {
    delete_preferences,
    get_preferences,
    preferences_order_update,
} from '../../api/Preferences_Api'
// import SortButton from "../../components/SortButton";
import { SearchTextField } from '../../components/Textfields'
import {
    onDragEndPrefences,
    onDragUpdatePreferences,
    set_message,
} from '../../redux/ActionCreators'
import { styles } from '../../styles/styles'
import AddNewPreference from './AddNewPreference'
import RowTask from './RowTask'
import deletee from '../../asset/img/DelShape.png'
import Store from '../../redux/Store'
import { get_all_dropdowns, get_user_by_id } from '../../api/UserRequest_Api'
import { useParams } from 'react-router-dom'
import {
    TextField,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    OutlinedInput,
    Select,
    MenuItem,
    CircularProgress,
} from '@mui/material'
import { Button as ButtonCore } from '@material-ui/core'
import { useForm } from 'react-hook-form'
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import PersonalInformation from './UserInformationFormComponents/PersonalInformation'
import LocationInformation from './UserInformationFormComponents/LocationInformation'
// import SocialProfiles from './UserInformationFormComponents/SocialProfiles'
import EthnicityAndReligion from './UserInformationFormComponents/EthnicityAndReligion'
import AppearanceAndLifestyle from './UserInformationFormComponents/AppearanceAndLifestyle'
import RelationshipAndCompatibility from './UserInformationFormComponents/RelationshipAndCompatibility'
import SubscriptionAndActivity from './UserInformationFormComponents/SubscriptionAndActivity'
import DisplayOptions from './UserInformationFormComponents/DisplayOptions'
import NotificationPreferences from './UserInformationFormComponents/NotificationPreferences'
import OtherInformation from './UserInformationFormComponents/OtherInformation'
import Preferences from './UserInformationFormComponents/Preferences'
import ProfileImages from './UserInformationFormComponents/ProfileImages'
// import CustomDropZone from '../../components/DropZone'
// import { styles } from '../../styles/styles'
import { DropzoneArea } from 'material-ui-dropzone'
import { submit_new_user } from '../../../src/api/Users_Api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import AdminSection from './UserInformationFormComponents/AdminSection'
import MatchMakeMeQuestionnair from './UserInformationFormComponents/Questionnair/index'
import UserImageUploadSection from './UserInformationFormComponents/UserImageUploadSection'
import { cmToFeetAndInches, feetAndInchesToCm } from '../../../src/Utils'
import AdvancedPreferences from './UserInformationFormComponents/AdvancedPrefernce/AdvancedPreferences'
import {
    EthnicityDropdownValues,
    ExerciseDropdownValues,
    FamilyPlansDropdownValues,
    LoveStyleDropdownValues,
    PoliticsDropdownValues,
    ReligionDropdownValues,
    dietaryDropdownValues,
} from '../../../src/Utils/advancedPreferenceDropDown'
import UserSettings from './UserInformationFormComponents/Settings'
const UserInformation = ({ search, loading }) => {
    const style = styles()
    const [initialLoading, setInitialLoading] = useState(true)
    const [userData, setUserData] = useState({})
    const [matchMakeMeDataObj, setMatchMakeMeDataObj] = useState([])
    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])
    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])
    const [valuesDropdownOptions, setValuesDropdownOptions] = useState([])
    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [statusDropdownOptions, setStatusDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])
    const [subscriptionDropdownOptions, setSubscriptionDropdownOptions] =
        useState([])

    const [
        preferencesGenderDropdownOptions,
        setPreferencesGenderDropdownOptions,
    ] = useState([])
    const [
        sexualOrientationDropdownPotions,
        setSexualOrientationDropdownPotions,
    ] = useState([])
    const [levelingDropdownOptions, setLevelingDropdownOptions] = useState([])
    const [familyPlansDropdownValues, setFamilyPlansDropdownValues] = useState(
        []
    )
    const [selectedDate, handleDateChange] = useState(new Date())
    // const [imgArr, setImgArr] = useState([1, 2, 3, 4, 5, 6])
    const [userImages, setUserImages] = useState([])
    const params = useParams()
    const history = useHistory()
    // const id = "62b46b7a4655008c69ebbb7f";
    const id = params.id
    // const [sort, set_sort] = React.useState({
    //   sort: -1,
    //   orderBy: "key",
    // });

    const form = useForm()
    const {
        register,
        setValue,
        watch,
        control,
        handleSubmit,
        reset,
        getValues,
        setError,
        setFocus,
        formState: { errors },
    } = form

    const validationAddUser = (data) => {
        console.log('data?.location', data?.location)
        let message = ''
        if (!data.firstName) {
            message = 'Personal Information: First Name is required'
        } else if (!data?.dateOfBirth) {
            message = 'Personal Information: Date Of Birth is required'
        } else if (!data?.gender) {
            message = 'Personal Information: Gender is required'
        } else if (!data?.email) {
            message = 'Personal Information: Email is required'
        } else if (!data?.mobileNumber) {
            message = 'Personal Information: Mobile Number is required'
        } else if (data?.address?.location && !data?.address?.location[0]) {
            message = 'Location Information: Longitude is required'
        } else if (data?.address?.location && !data?.address?.location[1]) {
            message = 'Location Information: Latitude is required'
        } else if (!data?.status) {
            message = 'Other Information: Status is required'
        }
        console.log('data_dattt', data.firstName, message)
        if (message) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: message,
                    color: 'error',
                })
            )
            return true
        }
    }

    const onSubmit = (data) => {
        if (validationAddUser(data)) return
        // setError('address.location[1]', {
        //     type: 'manual',
        //     message: 'latitude is required',
        // })
        // setFocus('address.location[1]')

        const questionnair = []
        for (const key in matchMakeMeDataObj) {
            if (Object.hasOwnProperty.call(matchMakeMeDataObj, key)) {
                const element = matchMakeMeDataObj[key]
                questionnair.push({ _id: key, response_text: element })
            }
        }

        const payload = {
            userId: id,
            userData: data,
            questionnair,
        }
        if (data?.status && data?.status === 'completed') {
            if (!data?.step) {
                data.step = 8
            }
        }

        //  else if (data?.status && data?.status === 'register') {
        //     if (!data?.step) {
        //         data.step = 0
        //     }
        // } else if (
        //     data?.status &&
        //     (data?.status === 'pending' || data?.status === 'waiting')
        // ) {
        //     if (!data?.step) {
        //         data.step = 4
        //     }
        // }

        if (data?.address?.location?.length === 2) {
            if (data?.preferences?.address?.location?.includes('')) {
                console.log('debug')
                data.preferences.address.location = data?.address?.location
            }
        }
        if (payload.userData?.height) {
            if (payload.userData?.height?.type === 'CM') {
                if (payload.userData?.height?.height_cm) {
                    payload.userData.height.height_feet = cmToFeetAndInches(
                        +payload.userData?.height.height_cm
                    )
                }
                payload.userData.height.height_cm =
                    payload.userData?.height.height_cm + '.0'
            } else if (payload.userData?.height?.type === 'FEET') {
                if (payload.userData?.height.height_feet) {
                    payload.userData.height.height_cm = feetAndInchesToCm(
                        payload.userData?.height.height_feet
                    )
                }
            }
        }
        if (
            payload?.userData?.advancedFilterPreferences?.height?.type === 'CM'
        ) {
            payload.userData.advancedFilterPreferences.height.from =
                payload.userData.advancedFilterPreferences?.height?.from + '.0'
            payload.userData.advancedFilterPreferences.height.to =
                payload.userData.advancedFilterPreferences?.height?.to + '.0'
        }
        if (
            payload?.userData?.advancedFilterPreferences?.height?.type === '0'
        ) {
            payload.userData.advancedFilterPreferences.height.from = null
            payload.userData.advancedFilterPreferences.height.to = null
            payload.userData.advancedFilterPreferences.height.type = null
        }
        for (const key in payload?.userData?.advancedFilterPreferences) {
            if (
                Object.hasOwnProperty.call(
                    payload?.userData?.advancedFilterPreferences,
                    key
                )
            ) {
                if (
                    Array.isArray(
                        payload?.userData?.advancedFilterPreferences[key]
                    )
                ) {
                    const advancedPrefDropdownsObj = {
                        lookingFor: lookingForDropdownOptions,
                        ethnicity: EthnicityDropdownValues,
                        religion: ReligionDropdownValues,
                        children: FamilyPlansDropdownValues,
                        salary: salaryDropdownOptions,
                        industry: industryDropdownOptions,
                        politics: PoliticsDropdownValues,
                        drinking: drinkingDropdownOptions,
                        personalityType: personalityDropdownValues,
                        zodiac: zodiacDropdownOptions,
                        exercise: ExerciseDropdownValues,
                        sleepingHabits: sleepingHabbitsDropdownOptions,
                        diet: dietaryDropdownValues,
                        relocateForLove: relocateForLoveDropdownOptions,
                        loveStyle: LoveStyleDropdownValues,
                        education: educationDropdownOptions,
                        smoking: smokingDropdownOptions,
                    }
                    let isOpenToAll =
                        payload?.userData?.advancedFilterPreferences[
                            key
                        ]?.includes('all')
                    if (isOpenToAll) {
                        payload.userData.advancedFilterPreferences[key] = []
                    } else {
                    }

                    if (
                        advancedPrefDropdownsObj[key]?.length ===
                        payload.userData.advancedFilterPreferences[key].length
                    ) {
                        payload.userData.advancedFilterPreferences[key] = []
                    }
                }
            }
        }
        if (payload?.userData?.pausedProfile?.status === false) {
            payload.userData.pausedProfile.paused_profile_end_time = null
        }
        submit_new_user(payload, () => {
            history.push('/user-management')
        })
        console.log(payload)
    }

    const handleSetUserImages = (imagesArr) => {
        setUserImages(imagesArr)
    }

    useEffect(() => {
        get_user_by_id(id)
            .then((res) => {
                if (res) {
                    if (res?.data?.user?.height?.type === 'CM') {
                        res.data.user.height.height_cm =
                            res.data.user.height.height_cm.replace(/\.0+$/, '')
                    }
                    const advancedFilterPrefObj =
                        res?.data?.user?.advancedFilterPreferences
                    if (advancedFilterPrefObj?.height?.type === 'CM') {
                        res.data.user.advancedFilterPreferences.height.from =
                            advancedFilterPrefObj?.height?.from.replace(
                                /\.0+$/,
                                ''
                            )
                        res.data.user.advancedFilterPreferences.height.to =
                            advancedFilterPrefObj?.height?.to.replace(
                                /\.0+$/,
                                ''
                            )
                    }

                    setUserData(res?.data?.user)
                    for (const field in res?.data?.user) {
                        setValue(field, res?.data?.user[field])
                    }
                    const advancedFilter = {
                        height: {
                            type: null,
                            from: null,
                            to: null,
                        },
                        lookingFor: [],
                        ethnicity: [],
                        religion: [],
                        children: [],
                        salary: [],
                        education: [],
                        industry: [],
                        politics: [],
                        smoking: [],
                        drinking: [],
                        zodiac: [],
                        loveStyle: [],
                        personalityType: [],
                        exercise: [],
                        diet: [],
                        sleepingHabits: [],
                        relocateForLove: [],
                    }
                    if (!advancedFilterPrefObj) {
                        if (
                            res?.data?.user?.subscription === 'COMMUNITY' &&
                            new Date(res?.data?.user.expirePackage) > new Date()
                        ) {
                            setValue(
                                'advancedFilterPreferences',
                                advancedFilter
                            )
                        }
                    } else if (advancedFilterPrefObj) {
                        for (const key in advancedFilterPrefObj) {
                            if (
                                Object.hasOwnProperty.call(
                                    advancedFilterPrefObj,
                                    key
                                )
                            ) {
                                const element = advancedFilterPrefObj[key]
                                if (Array.isArray(element)) {
                                    if (element.length === 0) {
                                        advancedFilter[key] = ['all']
                                    } else {
                                        advancedFilter[key] = element
                                    }
                                } else {
                                    advancedFilter[key] = element
                                }
                            }
                        }
                        setValue('advancedFilterPreferences', advancedFilter)
                    }
                    const images = res?.data?.user?.images ?? []
                    setUserImages(images)
                    setInitialLoading(false)
                    // setBirthDate(new Date(res?.data?.user?.dateOfBirth))
                }
            })
            .catch((e) => {
                setInitialLoading(false)
                console.log('-----------------', e.message)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const sexOrientation = res?.find((d) => {
                    if (d.dropdownTitle === 'sexual_orientation') {
                        return true
                    }
                })
                setSexualOrientationDropdownPotions(sexOrientation?.optionList)
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(salaries?.optionList)
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(industry?.optionList)
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(education?.optionList)
                const values = res?.find((d) => {
                    if (d.dropdownTitle === 'values') {
                        return true
                    }
                })
                setValuesDropdownOptions(values?.optionList)
                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(smoke?.optionList)
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(drink?.optionList)
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(personalityType?.optionList)
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(zodiac?.optionList)
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(relocateForLove?.optionList)

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(sleepingHabbits?.optionList)
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(looking?.optionList)
                const status = res?.find((d) => {
                    if (d.dropdownTitle === 'status') {
                        return true
                    }
                })

                setStatusDropdownOptions(status?.optionList)

                const subscriptionGuest = res?.find((d) => {
                    if (d.dropdownTitle === 'subscription') {
                        return true
                    }
                })
                console.log('subscriptionGuest', subscriptionGuest)

                setSubscriptionDropdownOptions(subscriptionGuest?.optionList)

                const prefGender = res?.find((d) => {
                    if (d.dropdownTitle === 'preferences_gender') {
                        return true
                    }
                })
                setPreferencesGenderDropdownOptions(prefGender?.optionList)
                const levelingDropOptions = res?.find((d) => {
                    if (d.dropdownTitle === 'leveling') {
                        return true
                    }
                })
                setLevelingDropdownOptions(levelingDropOptions?.optionList)
                const familyPlansDropdownValues = res?.find((d) => {
                    if (d.dropdownTitle === 'family_plans') {
                        return true
                    }
                })

                setFamilyPlansDropdownValues(
                    familyPlansDropdownValues?.optionList?.map(
                        (item) => item?.name
                    )
                )
            })
            .catch((e) => {
                console.log(e.message)
            })
    }, [])

    return !initialLoading ? (
        <Grid container>
            <Grid
                container
                alignItems="center"
                item
                xs={12}
                style={{ paddingTop: '10px' }}
            >
                <Grid item xs={4} style={{ display: 'contents' }}>
                    <Typography variant="h5" component="span">
                        {userData ? `Edit User` : 'Add User'}{' '}
                    </Typography>
                    <Typography
                        variant="h5"
                        component="span"
                        style={{
                            color: 'rgba(5, 6, 15,0.7)',
                            fontSize: '16px',
                        }}
                    >
                        {'('}
                        <span
                            style={{
                                color: '#dc3545',
                                fontSize: '14px',
                            }}
                        >
                            *
                        </span>
                        {' fields are mandatory'}
                        {')'}
                    </Typography>
                </Grid>
                {/* <Grid item xs={8} style={{ textAlign: 'end' }}>
                    {params?.id !== 'new' ? (
                        <ButtonCore
                            variant="contained"
                            style={{
                                borderRadius: 8,
                                background: '#BA5A6B',
                                color: '#FFFFFF',
                                marginRight: '10px',
                            }}
                            onClick={() => {
                                const win = window.open(
                                    `/user-connect-list/${params?.id}`
                                )
                            }}
                        >
                            MatchMake Me
                        </ButtonCore>
                    ) : null}
                </Grid> */}
            </Grid>

            <Grid item xs={12}>
                <PersonalInformation
                    form={form}
                    // errors={errors}
                    userData={userData}
                    dateOfBirth={userData?.dateOfBirth}
                    salaryDropdownOptions={salaryDropdownOptions}
                    industryDropdownOptions={industryDropdownOptions}
                    educationDropdownOptions={educationDropdownOptions}
                    sexualOrientationDropdownPotions={
                        sexualOrientationDropdownPotions
                    }
                />
                <LocationInformation
                    register={register}
                    // errors={errors}
                />
                {/* <SocialProfiles register={register} /> */}
                <EthnicityAndReligion
                    register={register}
                    valuesDropdownOptions={valuesDropdownOptions}
                    control={control}
                />
                <AppearanceAndLifestyle
                    register={register}
                    form={form}
                    smokingDropdownOptions={smokingDropdownOptions}
                    drinkingDropdownOptions={drinkingDropdownOptions}
                    control={control}
                />
                <RelationshipAndCompatibility
                    control={control}
                    register={register}
                    form={form}
                    familyPlansDropdownValues={familyPlansDropdownValues}
                />
                {/* <SubscriptionAndActivity
                    register={register}
                    setValue={setValue}
                    userData={userData}
                    control={control}
                    expirePackage={userData?.expirePackage}
                    lastActiveAt={userData?.lastActiveAt}
                    goldenAt={userData?.goldenAt}
                    deActiveAt={userData?.deActiveAt}
                    deletedAt={userData?.deletedAt}
                /> */}
                <SubscriptionAndActivity
                    form={form}
                    register={register}
                    setValue={setValue}
                    userData={userData}
                    subscriptionDropdownOptions={subscriptionDropdownOptions}
                    control={control}
                    expirePackage={userData?.expirePackage}
                    lastActiveAt={userData?.lastActiveAt}
                    goldenAt={userData?.goldenAt}
                    deActiveAt={userData?.deActiveAt}
                    deletedAt={userData?.deletedAt}
                />
                {/* register={register}
                    control={control}
                    timedBlock={userData?.timedBlock}
                    setValue={setValue}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                    statusDropdownOptions={statusDropdownOptions} */}
                <DisplayOptions register={register} control={control} />
                <NotificationPreferences
                    register={register}
                    control={control}
                />
                <OtherInformation
                    register={register}
                    control={control}
                    timedBlock={userData?.timedBlock}
                    setValue={setValue}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                    statusDropdownOptions={statusDropdownOptions}
                />
                <Preferences
                    register={register}
                    control={control}
                    preferencesGenderDropdownOptions={
                        preferencesGenderDropdownOptions
                    }
                />

                {userData?.expirePackage && userData?.subscription
                    ? userData.subscription === 'COMMUNITY' &&
                      new Date(userData.expirePackage) > new Date() && (
                          <AdvancedPreferences
                              form={form}
                              register={register}
                              setValue={setValue}
                              getValues={getValues}
                              control={control}
                              zodiacDropdownOptions={zodiacDropdownOptions}
                              sleepingHabbitsDropdownOptions={
                                  sleepingHabbitsDropdownOptions
                              }
                              drinkingDropdownOptions={drinkingDropdownOptions}
                              smokingDropdownOptions={smokingDropdownOptions}
                              relocateForLoveDropdownOptions={
                                  relocateForLoveDropdownOptions
                              }
                              personalityDropdownValues={
                                  personalityDropdownValues
                              }
                              industryDropdownOptions={industryDropdownOptions}
                              educationDropdownOptions={
                                  educationDropdownOptions
                              }
                              salaryDropdownOptions={salaryDropdownOptions}
                              lookingForDropdownOptions={
                                  lookingForDropdownOptions
                              }
                          />
                      )
                    : null}
                <MatchMakeMeQuestionnair
                    matchMakeMeDataObj={matchMakeMeDataObj}
                    setMatchMakeMeDataObj={setMatchMakeMeDataObj}
                />
                <AdminSection
                    register={register}
                    control={control}
                    levelingDropdownOptions={levelingDropdownOptions}
                />
                {console.log('userData', userData)}
                {params?.id !== 'new' ? (
                    <UserImageUploadSection
                        isGuest={
                            new Date(userData?.expirePackage) <= new Date()
                                ? true
                                : false
                        }
                        userId={params?.id}
                        images={userImages}
                        setUserImages={handleSetUserImages}
                    />
                ) : null}

                {userData?.expirePackage && userData?.subscription
                    ? userData.subscription === 'COMMUNITY' &&
                      new Date(userData.expirePackage) > new Date() && (
                          <UserSettings
                              watch={watch}
                              form={form}
                              userData={userData}
                          />
                      )
                    : null}

                {/* <ProfileImages images={userData?.images}/> */}
            </Grid>
            {/* <Grid item xs={12} className={style.box}>
                <Grid item xs={12}></Grid>
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Upload Photo
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    {imgArr.map((item, index) => {
                        return (
                            <Grid item xs={2}>
                                <Typography
                                    style={{ margin: '16px 0' }}
                                    variant="body1"
                                ></Typography>

                                <DropzoneArea
                                    //   dropzoneClass={classes.customDropzoneClass}
                                    Icon={() => {
                                        return (
                                            <Button
                                                color="secondary"
                                                style={{
                                                    backgroundColor: '#EDEAEA',
                                                    borderRadius: 12,
                                                }}
                                            >
                                                Browser file
                                            </Button>
                                        )
                                    }}
                                    onDrop={(files) => {
                                        setValue(
                                            `imageBase.${index}.new`,
                                            files[0]
                                        )
                                    }}
                                    onDelete={(file) => {
                                        setValue(
                                            `imageBase.${index}.new`,
                                            'deleted'
                                        )
                                    }}
                                    filesLimit={1}
                                    showAlerts={false}
                                    initialFiles={
                                        'https://csb10032001f4e69ada.blob.core.windows.net/imagecontainerlive/7ddbd47f-8923-416c-b894-8ae03460a48e.png'
                                    }
                                    acceptedFiles={[
                                        'image/jpeg',
                                        'image/png',
                                        'image/bmp',
                                    ]}
                                    previewComponentProps={{
                                        style: {
                                            width: '500px',
                                            height: '500px',
                                            objectFit: 'cover',
                                        },
                                    }}
                                    dropzoneText="Drag your image here or "
                                    classes={{
                                        icon: style.dropBox,
                                        text: style.dropBox,
                                        root: style.DropzoneAreaRoot,
                                        textContainer: style.DropzoneArea,
                                    }}
                                    clearOnUnmount
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid> */}
            <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                style={{ padding: '20px' }}
                className={style.box}
            >
                <ButtonCore
                    variant="outlined"
                    color="default"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                        reset()
                    }}
                >
                    clear
                </ButtonCore>
                <ButtonCore
                    variant="contained"
                    style={{
                        borderRadius: 8,
                        background: '#BA5A6B',
                        color: '#FFFFFF',
                        marginLeft: '8px',
                    }}
                    onClick={handleSubmit(onSubmit)}
                >
                    Submit
                </ButtonCore>
            </Grid>
        </Grid>
    ) : (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    )
}

const mapStateToProps = ({ GeneralReducer: { search, loading } }) => {
    return { search, loading }
}

export default connect(mapStateToProps)(UserInformation)

/*
<img src="https://csb10032001f4e69ada.blob.core.windows.net/imagecontainerlive/3322c79c-0306-453f-a969-c9c8f7f284a1.png" alt="" width="130" height="150" style="border-radius: 4px;">
*/
