import {
    Avatar,
    Divider,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'

import React, { useState } from 'react'

import { styles } from '../../../styles/styles'
import RowAction from '.././RowAction'
import sorter from '../../../asset/img/sorter.png'
import RecommededUserIcon from '../../../asset/img/Recommend_icon.png'
// import clock from "../../asset/img/clock.png";
import { get_all_dropdowns } from '../../../api/UserRequest_Api'
import {
    get_matchmakeme_users_list,
    get_user_all_matching_info,
} from '../../../api/Users_Api'
import moment from 'moment'
import { CircularProgress, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import BasicPagination from '.././Pagination'
import MatchMakeMeTabsInfo from './MatchMakeMeTabsInfo'

const MatchMakeMeUsers = () => {
    const params = useParams()
    const [userName, setUserName] = useState('')
    const [userFullName, setUserFullName] = useState('')
    const [loader, setLoader] = useState(true)
    const [initialLoading, setInitialLoading] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [error, setError] = useState(false)
    const [deleteMuatation, setDeleteMutation] = useState({})

    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])

    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])

    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])

    const [tabInitialValue, setTabInitialValue] = useState('MATCHMAKEME')
    const [matchMakeMeCount, setMatchMakeMeCount] = useState(0)
    const [counts, set_count] = useState({
        liker: 0,
        liked: 0,
        disliker: 0,
        disliked: 0,
        matches: 0,
    })

    const [paginateObj, setPaginateObj] = useState({ page: 1, totalPages: 1 })

    const tabClickedCallback = () => {
        set_Data([])
    }

    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(
                    salaries?.optionList?.map((item) => item?.name)
                )
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(
                    industry?.optionList?.map((item) => item?.name)
                )
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(
                    education?.optionList?.map((item) => item?.name)
                )

                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(
                    smoke?.optionList?.map((item) => item?.name)
                )
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(
                    drink?.optionList?.map((item) => item?.name)
                )
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(
                    personalityType?.optionList?.map((item) => item?.name)
                )
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(
                    zodiac?.optionList?.map((item) => item?.name)
                )
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(
                    relocateForLove?.optionList?.map((item) => item?.name)
                )

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(
                    sleepingHabbits?.optionList?.map((item) => item?.name)
                )
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(
                    looking?.optionList?.map((item) => item?.name)
                )
            })
            .catch((e) => console.log(e.message))

        get_user_all_matching_info(
            params?.id,
            tabInitialValue,
            paginateObj?.page
        )
            .then((res) => {
                if (res?.response?.data?.data?.error?.message) {
                    setError(true)
                    setLoader(false)
                } else {
                    const responseData = res?.data
                    console.log('responseData', responseData)
                    set_count({
                        liker: responseData?.topBar?.liker,
                        liked: responseData?.topBar?.liked,
                        disliker: responseData?.topBar?.disliker,
                        disliked: responseData?.topBar?.disliked,
                        matches: responseData?.topBar?.matches,
                    })
                    setPaginateObj({
                        page: responseData?.pagination?.page,
                        totalPages: responseData?.pagination?.totalPages,
                    })
                    set_Data(responseData?.reqQueryData?.data)
                    setLoader(false)
                }
            })
            .catch((err) => {
                setLoader(false)
                setInitialLoading(false)
                setError(true)
                console.log('error', err?.response)
            })
    }, [])
    React.useEffect(() => {
        setLoader(true)
        const userId = params?.id
        if (tabInitialValue === 'MATCHMAKEME') {
            get_matchmakeme_users_list(userId, 1)
                .then((res) => {
                    if (res?.response?.data?.data?.error?.message) {
                        setError(true)
                        setLoader(false)
                        setInitialLoading(false)
                    } else {
                        const responseData = res?.data?.data
                        set_Data(responseData?.matchedUsers)
                        setMatchMakeMeCount(responseData?.matchedUsers?.length)
                        setUserName(res?.data?.firstName?.trim())
                        setUserFullName(res?.data?.userName?.trim())
                        setError(false)
                        setInitialLoading(false)
                        setLoader(false)
                        set_count((prev) => ({
                            ...prev,
                            matchMakeMe: responseData?.matchedUsers?.length,
                        }))
                    }
                })
                .catch((err) => {
                    setInitialLoading(false)
                    setLoader(false)
                    setError(true)
                    console.log('error', err?.response)
                })
        } else {
            get_user_all_matching_info(
                userId,
                tabInitialValue,
                paginateObj?.page
            )
                .then((res) => {
                    if (res?.response?.data?.data?.error?.message) {
                        setError(true)
                        setLoader(false)
                    } else {
                        const responseData = res?.data
                        console.log('responseData', responseData)
                        set_count({
                            liker: responseData?.topBar?.liker,
                            liked: responseData?.topBar?.liked,
                            disliker: responseData?.topBar?.disliker,
                            disliked: responseData?.topBar?.disliked,
                            matches: responseData?.topBar?.matches,
                        })
                        setPaginateObj({
                            page: responseData?.pagination?.page,
                            totalPages: responseData?.pagination?.totalPages,
                        })
                        set_Data(responseData?.reqQueryData?.data)
                        setError(false)
                        setLoader(false)
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    setError(true)
                    console.log('error', err?.response)
                })
        }
    }, [tabInitialValue, paginateObj?.page, deleteMuatation])

    const header_list = [
        {
            field: 'fullName',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                        }}
                        variant="body1"
                    >
                        User
                    </Typography>
                </Stack>
            ),
            minWidth: 270,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Avatar
                            src={`${value.row?.images[0]?.croped}`}
                            style={{ marginRight: 16, width: 45, height: 45 }}
                        />
                        <Grid
                            container
                            alignItems="left"
                            direction="column"
                            item
                            style={{ width: 'calc(100% - 62px)' }}
                        >
                            <Grid container alignItems="center">
                                <Typography
                                    style={{ opacity: 1, color: 'black' }}
                                    variant="body1"
                                >
                                    {(value?.row?.firstName ?? '') +
                                        ' ' +
                                        (value?.row?.lastName ?? '')}
                                    {/* <Link to={`/user-information/${value.id}`}>{value.value}</Link> */}
                                </Typography>
                            </Grid>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {moment(value.row?.dateOfBirth).format(
                                    'DD.MM.YYYY'
                                )}
                            </Typography>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {`${value.row?.address?.city} ,${value?.row?.address?.country}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'email',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Email
                    </Typography>
                </Stack>
            ),
            minWidth: 230,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        {value?.row?.email ? value?.row?.email : '-'}
                    </Typography>

                    <Typography variant="body1" style={{ fontSize: 12 }}>
                        {value.row.mobileNumber ?? '-'}
                    </Typography>
                </Stack>
            ),
        },
        // {
        //     field: 'phoneNumber',
        //     headerName: (
        //         <Stack
        //             direction="column"
        //             justifyContent={'space-around'}
        //             alignItems="left"
        //         >
        //             <Typography
        //                 style={{
        //                     opacity: 1,
        //                     color: 'black',
        //                     paddingTop: '12px',
        //                     marginLeft: '5px',
        //                 }}
        //                 variant="body1"
        //             >
        //                 Phone Number
        //             </Typography>
        //         </Stack>
        //     ),
        //     minWidth: 230,
        //     sortable: false,
        //     flex: 1,
        //     headerAlign: 'left',
        //     align: 'left',

        //     renderCell: (value) => (
        //         <Stack>
        //             {
        //                 <Typography
        //                     variant="body1"
        //                     style={{
        //                         fontSize: 12,
        //                         color: 'black',
        //                         marginLeft: '10px',
        //                     }}
        //                 >
        //                     {value?.row?.mobileNumber
        //                         ? value?.row?.mobileNumber
        //                         : 'NA'}
        //                 </Typography>
        //             }
        //         </Stack>
        //     ),
        // },

        {
            field: 'lastActiveAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Last Active
                    </Typography>
                </Stack>
            ),
            minWidth: 130,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{
                                    fontSize: 12,
                                    color: 'black',
                                    marginLeft: '10px',
                                }}
                                variant="body1"
                            >
                                {format(
                                    new Date(
                                        value?.row?.lastActiveAt
                                            ? value?.row?.lastActiveAt
                                            : 'NA'
                                    ),
                                    'dd LLL uuuu'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },

        {
            field: 'recommendUser',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                        }}
                        variant="body1"
                    >
                        Recommendation Status
                    </Typography>
                </Stack>
            ),
            minWidth: 80,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',
            renderCell: (value) => (
                <Grid style={{ width: '160px', textAlign: 'center' }}>
                    {value?.row?.isMatchMakeMeUserRecommended ||
                    value?.row?.recommendUser?.status ? (
                        <img
                            src={RecommededUserIcon}
                            alt="recommend user icon"
                        />
                    ) : (
                        <div>-</div>
                    )}
                </Grid>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 95,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (value) => (
                <RowAction
                    zodiacDropdownOptions={zodiacDropdownOptions}
                    sleepingHabbitsDropdownOptions={
                        sleepingHabbitsDropdownOptions
                    }
                    drinkingDropdownOptions={drinkingDropdownOptions}
                    smokingDropdownOptions={smokingDropdownOptions}
                    relocateForLoveDropdownOptions={
                        relocateForLoveDropdownOptions
                    }
                    personalityDropdownValues={personalityDropdownValues}
                    industryDropdownOptions={industryDropdownOptions}
                    educationDropdownOptions={educationDropdownOptions}
                    salaryDropdownOptions={salaryDropdownOptions}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                    setDeleteMutation={setDeleteMutation}
                    value={value}
                />
            ),
        },
    ]

    return initialLoading ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    ) : error ? (
        'No Data Found'
    ) : (
        <>
            <Grid xs={12} item>
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                    <Typography variant="h5">{`${userFullName}`}</Typography>
                </Grid>
                {/* <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography variant="body1">
                                    View and manage users
                                </Typography>
                            </Grid>
                        </Grid> */}
            </Grid>
            <MatchMakeMeTabsInfo
                tabClickedCallback={tabClickedCallback}
                loader={loader}
                userName={userName}
                matchMakeMeCount={matchMakeMeCount}
                counts={counts}
                tabInitialValue={tabInitialValue}
                setTabInitialValue={(tabsValue) => {
                    setTabInitialValue(tabsValue)
                    setPaginateObj({ page: 1, totalPages: 1 })
                }}
            />{' '}
            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{
                    height: 'calc(100% - 180px)',
                    overflow: 'auto',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                }}
            >
                <DataGrid
                    columns={header_list}
                    autore
                    rows={data}
                    loading={loader}
                    disableColumnMenu
                    noda
                    getRowId={(row) => row._id}
                    hideFooter
                    style={{
                        border: 'none',
                        fontSize: 14,
                    }}
                    classes={{ cell: style.DataGridHeader }}
                    headerHeight={70}
                    rowHeight={90}
                    disableSelectionOnClick
                    disableColumnSelector
                    components={{
                        ColumnSortedAscendingIcon: CustomSort,
                        ColumnSortedDescendingIcon: CustomSort,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                />
            </Grid>
            {tabInitialValue === 'MATCHMAKEME' ? null : paginateObj ? (
                paginateObj?.totalPages ? (
                    loader ? null : (
                        <Grid item xs={12}>
                            <BasicPagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    tabClickedCallback()
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                    })
                                }}
                            />
                        </Grid>
                    )
                ) : null
            ) : null}
        </>
    )
}

export default MatchMakeMeUsers
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>

            {/* <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color="secondary" />
            </div> */}
        </GridOverlay>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
