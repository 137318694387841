import { Grid } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import React from "react";

const NoData = () => {
  return (
    <Grid
      container
      style={{ height: "50%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Error color="secondary" style={{ fontSize: 60 }} />
    </Grid>
  );
};

export default NoData;
