import { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
    TextField,
    FormGroup,
    FormControlLabel,
    Stack,
    Checkbox,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../../styles/styles'
import { Controller } from 'react-hook-form'
import {
    EthnicityDropdownValues,
    ExerciseDropdownValues,
    FamilyPlansDropdownValues,
    LoveStyleDropdownValues,
    PoliticsDropdownValues,
    ReligionDropdownValues,
    dietaryDropdownValues,
} from '../../../../Utils/advancedPreferenceDropDown'
import {
    cmHeightArrForUser,
    feetHeightArrForUser,
    heightTypeArr,
} from '../../../../Utils'
import SelectAdvancedPreferences from './Select'

const AdvancedPreferences = ({
    form,
    lookingForDropdownOptions,
    relocateForLoveDropdownOptions,
    sleepingHabbitsDropdownOptions,
    personalityDropdownValues,
    zodiacDropdownOptions,
    drinkingDropdownOptions,
    smokingDropdownOptions,
    register,
    educationDropdownOptions,
    industryDropdownOptions,
    salaryDropdownOptions,
    control,
    setValue,
    getValues,
}) => {
    const advancePreferenceHeightType = form.watch(
        'advancedFilterPreferences.height.type'
    )

    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-advanced-preference-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Advanced Preferences
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {lookingForDropdownOptions?.length ? (
                        <Grid container spacing={2}>
                            <SelectAdvancedPreferences
                                label={'Looking For'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                name={'advancedFilterPreferences.lookingFor'}
                                options={lookingForDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Ethnicity'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={false}
                                name={'advancedFilterPreferences.ethnicity'}
                                options={EthnicityDropdownValues}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Religion'}
                                setValue={setValue}
                                getValue={getValues}
                                name={'advancedFilterPreferences.religion'}
                                options={ReligionDropdownValues}
                                isOptionName={false}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Family Plans'}
                                setValue={setValue}
                                getValue={getValues}
                                name={'advancedFilterPreferences.children'}
                                options={FamilyPlansDropdownValues}
                                isOptionName={false}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Salary'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                name={'advancedFilterPreferences.salary'}
                                options={salaryDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <Grid item container xs={4}>
                                <Grid item xs={4}>
                                    <Stack direction="column">
                                        <FormLabel
                                            htmlFor="advancedFilterPreferences.height.type"
                                            sx={{ pt: 4 }}
                                        >
                                            Height Type:
                                        </FormLabel>
                                        <FormControlLabel
                                            style={{
                                                paddingTop: '16px',
                                                paddingLeft: '12px',
                                            }}
                                            control={
                                                <Controller
                                                    {...register(
                                                        'advancedFilterPreferences.height.type'
                                                    )}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            labelId="advancedFilterPreferences.height.type"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            margin="normal"
                                                            size="small"
                                                            value={
                                                                field.value
                                                                    ? field.value
                                                                    : ''
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                field.onChange(
                                                                    event.target
                                                                        .value
                                                                )
                                                            }}
                                                        >
                                                            <MenuItem
                                                                value={'0'}
                                                            >
                                                                Select Height
                                                                Type
                                                            </MenuItem>
                                                            {heightTypeArr?.map(
                                                                (option) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                option
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        </Select>
                                                    )}
                                                />
                                            }
                                        />
                                    </Stack>
                                </Grid>

                                {advancePreferenceHeightType === 'CM' ? (
                                    <>
                                        <Grid item xs={4}>
                                            <Stack direction="column">
                                                <FormLabel
                                                    htmlFor="advancedFilterPreferences.height.from"
                                                    sx={{ pt: 4 }}
                                                >
                                                    From:
                                                </FormLabel>
                                                <FormControlLabel
                                                    style={{
                                                        paddingTop: '16px',
                                                        paddingLeft: '12px',
                                                    }}
                                                    control={
                                                        <Controller
                                                            {...register(
                                                                'advancedFilterPreferences.height.from'
                                                            )}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Select
                                                                    labelId="advancedFilterPreferences.height.from"
                                                                    id="demo-simple-select"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    size="small"
                                                                    value={
                                                                        field.value
                                                                            ? field.value
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        field.onChange(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }}
                                                                    defaultValue={
                                                                        '0'
                                                                    }
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            '0'
                                                                        }
                                                                    >
                                                                        Select
                                                                        Height
                                                                    </MenuItem>
                                                                    {cmHeightArrForUser?.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    value={
                                                                                        option
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Select>
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack direction="column">
                                                <FormLabel
                                                    htmlFor="advancedFilterPreferences.height.to"
                                                    sx={{ pt: 4 }}
                                                >
                                                    To:
                                                </FormLabel>
                                                <FormControlLabel
                                                    style={{
                                                        paddingTop: '16px',
                                                        paddingLeft: '12px',
                                                    }}
                                                    control={
                                                        <Controller
                                                            {...register(
                                                                'advancedFilterPreferences.height.to'
                                                            )}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Select
                                                                    labelId="advancedFilterPreferences.height.to"
                                                                    id="demo-simple-select"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    size="small"
                                                                    value={
                                                                        field.value
                                                                            ? field.value
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        field.onChange(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }}
                                                                    defaultValue={
                                                                        '0'
                                                                    }
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            '0'
                                                                        }
                                                                    >
                                                                        Select
                                                                        Height
                                                                    </MenuItem>
                                                                    {cmHeightArrForUser?.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    value={
                                                                                        option
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Select>
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={4}>
                                            <Stack direction="column">
                                                <FormLabel
                                                    htmlFor="advancedFilterPreferences.height.from"
                                                    sx={{ pt: 4 }}
                                                >
                                                    From:
                                                </FormLabel>
                                                <FormControlLabel
                                                    style={{
                                                        paddingTop: '16px',
                                                        paddingLeft: '12px',
                                                    }}
                                                    control={
                                                        <Controller
                                                            {...register(
                                                                'advancedFilterPreferences.height.from'
                                                            )}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Select
                                                                    labelId="advancedFilterPreferences.height.from"
                                                                    id="demo-simple-select"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    size="small"
                                                                    value={
                                                                        field.value
                                                                            ? field.value
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        field.onChange(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }}
                                                                    defaultValue={
                                                                        '0'
                                                                    }
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            '0'
                                                                        }
                                                                    >
                                                                        Select
                                                                        Height
                                                                    </MenuItem>
                                                                    {feetHeightArrForUser?.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    value={
                                                                                        option
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Select>
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Stack direction="column">
                                                <FormLabel
                                                    htmlFor="advancedFilterPreferences.height.to"
                                                    sx={{ pt: 4 }}
                                                >
                                                    To:
                                                </FormLabel>
                                                <FormControlLabel
                                                    style={{
                                                        paddingTop: '16px',
                                                        paddingLeft: '12px',
                                                    }}
                                                    control={
                                                        <Controller
                                                            {...register(
                                                                'advancedFilterPreferences.height.to'
                                                            )}
                                                            control={control}
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <Select
                                                                    labelId="advancedFilterPreferences.height.to"
                                                                    id="demo-simple-select"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    size="small"
                                                                    value={
                                                                        field.value
                                                                            ? field.value
                                                                            : ''
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        field.onChange(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }}
                                                                    defaultValue={
                                                                        '0'
                                                                    }
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            '0'
                                                                        }
                                                                    >
                                                                        Select
                                                                        Height
                                                                    </MenuItem>
                                                                    {feetHeightArrForUser?.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    value={
                                                                                        option
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Select>
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <SelectAdvancedPreferences
                                label={'Education'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                name={'advancedFilterPreferences.education'}
                                options={educationDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Industry'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                name={'advancedFilterPreferences.industry'}
                                options={industryDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Politics'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={false}
                                name={'advancedFilterPreferences.politics'}
                                options={PoliticsDropdownValues}
                                register={register}
                                control={control}
                            />

                            <SelectAdvancedPreferences
                                label={'Smoking'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                name={'advancedFilterPreferences.smoking'}
                                options={smokingDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Drinking'}
                                isOptionName={true}
                                setValue={setValue}
                                getValue={getValues}
                                name={'advancedFilterPreferences.drinking'}
                                options={drinkingDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Zodiac'}
                                isOptionName={true}
                                setValue={setValue}
                                getValue={getValues}
                                name={'advancedFilterPreferences.zodiac'}
                                options={zodiacDropdownOptions}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Love Style'}
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={false}
                                name={'advancedFilterPreferences.loveStyle'}
                                options={LoveStyleDropdownValues}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Personality Type'}
                                name={
                                    'advancedFilterPreferences.personalityType'
                                }
                                isOptionName={true}
                                setValue={setValue}
                                getValue={getValues}
                                options={personalityDropdownValues}
                                register={register}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Exercise'}
                                isOptionName={false}
                                setValue={setValue}
                                getValue={getValues}
                                name={'advancedFilterPreferences.exercise'}
                                options={ExerciseDropdownValues}
                                register={register}
                                control={control}
                            />

                            <SelectAdvancedPreferences
                                label={'Sleeping Habits'}
                                name={
                                    'advancedFilterPreferences.sleepingHabits'
                                }
                                isOptionName={true}
                                options={sleepingHabbitsDropdownOptions}
                                register={register}
                                setValue={setValue}
                                getValue={getValues}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Dietary Preference'}
                                isOptionName={false}
                                name={'advancedFilterPreferences.diet'}
                                options={dietaryDropdownValues}
                                register={register}
                                setValue={setValue}
                                getValue={getValues}
                                control={control}
                            />
                            <SelectAdvancedPreferences
                                label={'Relocate For Love'}
                                name={
                                    'advancedFilterPreferences.relocateForLove'
                                }
                                setValue={setValue}
                                getValue={getValues}
                                isOptionName={true}
                                options={relocateForLoveDropdownOptions}
                                register={register}
                                control={control}
                            />

                            <Grid item container xs={4}>
                                <Grid item xs={12}>
                                    <Stack direction="column">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        {...register(
                                                            'isShowOtherPeopleIfRunOut'
                                                        )}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                checked={
                                                                    field?.value ||
                                                                    false
                                                                }
                                                                onChange={(e) =>
                                                                    field.onChange(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                }
                                                label="Show me other people if I run out"
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default AdvancedPreferences
